
import { menu } from '../router';
import { useRouter } from 'vue-router';
import { useStores } from '@/store/Stores';

export default {
  setup() {
    const { accountStoreState } = useStores();

    const router = useRouter();
    router.push(menu[accountStoreState.role][0].to);
  }
};
